import * as THREE from "three"
import ReactDOM from "react-dom"
import React, { Suspense, useRef, useState, useCallback, useEffect, useMemo } from "react"
import { Canvas } from "react-three-fiber"
import Particles from './Particles'
import Particles2 from './Particles2'

import logo from './logo.svg';
import './App.css';

import Model from './Model.js'
import Particles3 from './Particles3.js'
import Smoke from './Smoke.js'

// import Shader from './Shader.js'

function App() {
  const [hovered, hover] = useState(false)
  const [down, set] = useState(false)
  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  return (
    <div className="App">
      <div className="bigWrap">
      <div className="topWrap">

        <div className="socialIcons">
        <a href="https://facebook.com/lovemafiacollective"><img className="iconImage" src="fb2.png" alt="Love Mafia Facebook"></img></a>
          <a href="https://instagram.com/lovemafiacollective"><img className="iconImage" src="ig2.png" alt="Love Mafia Instagram"></img></a>
          <a href="https://twitter.com/lovemafiacollec"><img className="iconImage" src="tw2.png" alt="Love Mafia Twitter"></img></a>
        </div>
        </div>
        <div className="logoWrap">
        <img className="logoImage" src="LMLogo2.png" alt="Love Mafia Logo"></img>
        </div>
      <Canvas
      // gl={{ antialias: true }} 
      // pixelRatio={window.devicePixelRatio}
      colorManagement
      onMouseMove={onMouseMove}
      onMouseUp={() => set(false)}
      onMouseDown={() => set(true)}
      style={{ display: "flex", height: "100vh", flexDirection: "column", alignItems: "center" }}
      shadowMap
      camera={{ position: [0, 0, 100], fov: 40, far: 10000 }}
      // onCreated={({ gl }) => {
      //   // gl.toneMapping = THREE.Uncharted2ToneMapping
      //   gl.setClearColor(new THREE.Color('#e9e6f0'))
      //   gl.gammaInput = true
      //   gl.toneMapping = THREE.ACESFilmicToneMapping
      // }}
      >
      {/* <ambientLight intensity={1} /> */}
      <pointLight position={[-100,0,100]} intensity={1}/>
      {/* <pointLight position={[0,-100,0]} intensity={2}/> */}
      <directionalLight 
        castShadow
        intensity={1.5} 
        position={[0, 10, 0]} 
        shadow-mapSize-width={1024} 
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
        color="white" />
      {/* <hemisphereLight skyColor="#000000" groundColor="#000000" intensity="1" /> */}
      {/* <spotLight */}
        {/* intensity={0.8}
        position={[0, 0, 1000]}
        angle={0.2}
        penumbra={1}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
      /> */}
      {/* <fogExp2 
        color="0x03544e"
        density="0.001"/> */}
        {/* <directionalLight 
        castShadow
        intensity={10} 
        position={[0, 0, 0]} 
        color="#fdee36" /> */}
      <Suspense fallback={null}>
        <Model />
        <Smoke count={isMobile ? 400 : 500} mouse={mouse} />
        {/* <Model2 /> */}
      </Suspense>
      {/* <Particles count={isMobile ? 5000 : 10000} mouse={mouse} /> */}
      {/* <Particles2 count={isMobile ? 30 : 40} mouse={mouse} /> */}
      {/* <Effects /> */}
      {/* <Particles3 count={10} /> */}


    </Canvas>
    </div>
    </div>
  );
}

export default App;
